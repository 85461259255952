import styled from "@emotion/styled";
import { CircularProgress, type TooltipProps } from "@mui/material";
import Button, { type ButtonProps } from "@mui/material/Button";
import { isPropValid } from "Infrastructure/Utils/StyleUtils";
import { BlTooltip } from "Shared/BlTooltip";
import type * as React from "react";

export type DefaultButtonProps = Omit<ButtonProps, "title"> & {
	title?: string | JSX.Element;
	placement?: TooltipProps["placement"];
	tooltipColor?: {
		background: string;
		text: string;
	};
	isSquare?: boolean;
	isLoading?: boolean;
};

const StyledButton = styled(Button, { shouldForwardProp: isPropValid })<{
	$isSquare: boolean;
}>`
  min-width: 0;
  ${(props) => props.$isSquare && "aspect-ratio: 1;"}

  &.Mui-disabled {
    opacity: 0.5;
  }
`;

export const BlDefaultButton: React.FunctionComponent<DefaultButtonProps> = (
	props,
) => {
	const { title, tooltipColor, isSquare, isLoading, children, ...rest } = props;
	const t = title || "";

	const buttonContent = isLoading ? <CircularProgress size={18} /> : children;

	return (
		<>
			{t ? (
				<BlTooltip
					title={t}
					placement={props.placement ?? "top"}
					tooltipColor={tooltipColor}
				>
					<div>
						<StyledButton
							{...rest}
							color={props.color ?? "primary"}
							$isSquare={isSquare ?? false}
						>
							{buttonContent}
						</StyledButton>
					</div>
				</BlTooltip>
			) : (
				<div>
					<StyledButton
						{...rest}
						color={props.color ?? "primary"}
						$isSquare={isSquare ?? false}
					>
						{buttonContent}
					</StyledButton>
				</div>
			)}
		</>
	);
};
