import type { PSD2Environment } from "Infrastructure/Api/Api";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { atomWithStorage } from "jotai/utils";

type TokenData = {
	accessToken: string | null;
	refreshToken: string | null;
};

type EnvironmentTokens = {
	[key in PSD2Environment]?: TokenData;
};
const PSD2_TOKENS_KEY = "psd2_tokens";
// Base atom for all tokens
const tokensAtom = atomWithStorage<EnvironmentTokens>(PSD2_TOKENS_KEY, {});

// Atom family for individual environment tokens
export const tokenFamily = atomFamily((environment: PSD2Environment) =>
	atom(
		(get) =>
			get(tokensAtom)[environment] || { accessToken: null, refreshToken: null },
		(get, set, newTokens: Partial<TokenData>) => {
			const prev = get(tokensAtom);
			set(tokensAtom, {
				...prev,
				[environment]: { ...prev[environment], ...newTokens },
			});
		},
	),
);

export const clearPsd2Tokens = () => {
	localStorage.removeItem(PSD2_TOKENS_KEY);
};
