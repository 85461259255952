import { Box, Button, Typography } from "@mui/material";
import type { PSD2Environment } from "Infrastructure/Api/Api";
import { useAispAccountsQuery } from "Psd2/Api/Queries/useAispAccountsQuery";
import { AispDataDisplay } from "Psd2/Components/AispDataDisplay";
import type React from "react";

interface AispSectionProps {
	environment: PSD2Environment;
	accessToken: string;
}

export const AispSection: React.FC<AispSectionProps> = ({
	environment,
	accessToken,
}) => {
	const {
		data: aispAccountsData,
		isLoading: isLoadingAispAccounts,
		fetchAispAccounts,
	} = useAispAccountsQuery(environment, accessToken);

	return (
		<Box>
			<Typography variant="h6">AISP</Typography>
			<Button
				variant="contained"
				color="primary"
				onClick={fetchAispAccounts}
				disabled={isLoadingAispAccounts}
			>
				{isLoadingAispAccounts ? "Loading..." : "Load Accounts"}
			</Button>
			{aispAccountsData && (
				<AispDataDisplay accounts={aispAccountsData.accounts} />
			)}
		</Box>
	);
};
