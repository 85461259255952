import { useUserInfoQuery } from "Auth/Api/Queries/useUserInfoQuery";
import { ApplicationErrorPage } from "Infrastructure/Pages/ApplicationErrorPage";
import { AppRouting } from "Infrastructure/Utils/UrlUtils";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate } from "react-router-dom";

type Props = {};

export const AuthenticatedRoute: React.FC<React.PropsWithChildren<Props>> = ({
	children,
}) => {
	const { data, isLoading } = useUserInfoQuery();

	if (isLoading) return <div />;

	if (data?.status === 401) {
		return <Navigate to={AppRouting.SignIn.path()} replace />;
	}

	return (
		<ErrorBoundary FallbackComponent={ApplicationErrorPage}>
			{children}
		</ErrorBoundary>
	);
};
