import { useQuery } from "@tanstack/react-query";
import {
	type PSD2Environment,
	getPsd2AispAccounts,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const AISP_ACCOUNTS_QUERY_KEY = "aispAccounts";

export function useAispAccountsQuery(
	environment: PSD2Environment,
	accessToken: string | null,
) {
	const query = useQuery({
		queryKey: [AISP_ACCOUNTS_QUERY_KEY, environment],
		queryFn: accessToken
			? processResponse(() => getPsd2AispAccounts(accessToken, environment))
			: undefined,
		enabled: false, // This prevents the query from running automatically
	});

	// Function to manually trigger the query
	const fetchAispAccounts = () => {
		if (accessToken) {
			query.refetch();
		} else {
			console.error("Access token is missing");
		}
	};

	return { ...query, fetchAispAccounts };
}
