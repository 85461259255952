/* eslint-disable */
// THIS FILE WAS GENERATED
// ALL CHANGES WILL BE OVERWRITTEN

// INFRASTRUCTURE START
  export type StandardError = globalThis.Error;
  export type Error500s = 501 | 502 | 503 | 504 | 505 | 506 | 507 | 508 | 510 | 511;
  export type ErrorStatuses = 0 | Error500s;
  export type ErrorResponse = FetchResponse<unknown, ErrorStatuses>;

  export type FetchResponseOfError = {
    data: null;
    error: StandardError;
    status: ErrorStatuses;
    args: any;
  };

  export type FetchResponseOfSuccess<TData, TStatus extends number = 0> = 
  {
    data: TData;
    error: null;
    status: TStatus;
    args: any;
    responseHeaders: Headers;
  };

  export type FetchResponse<TData, TStatus extends number = 0> = 
    TStatus extends ErrorStatuses ? FetchResponseOfError: FetchResponseOfSuccess<TData, TStatus>;

  export type TerminateRequest = null;
  export type TerminateResponse = null;

  export type Configuration = {
    apiUrl: string | (() => string);
    jwtKey: string | undefined | (() => string | null | undefined);
    requestMiddlewares?: Array<{
      name: string;
      fn: (request: FetchArgs) => FetchArgs | TerminateRequest;
    }>;
    responseMiddlewares?: Array<{
      name: string;
      fn: (
        response: FetchResponse<unknown, any>
      ) => FetchResponse<unknown, any> | TerminateResponse;
    }>;
  };

  let CONFIG: Configuration = {
    apiUrl: () => "",
    jwtKey: undefined,
    requestMiddlewares: [],
    responseMiddlewares: [],
  };

  export function setupClient(configuration: Configuration) {
    CONFIG = {
      ...CONFIG,
      ...configuration,
      requestMiddlewares: [
        ...(CONFIG.requestMiddlewares || []),
        ...(configuration.requestMiddlewares || []),
      ],
      responseMiddlewares: [
        ...(CONFIG.responseMiddlewares || []),
        ...(configuration.responseMiddlewares || []),
      ],
    };
  }

  export function getApiUrl() {
    if (typeof CONFIG.apiUrl === "function") {
      return CONFIG.apiUrl();
    }
    return CONFIG.apiUrl;
  }

  export type Termination = {
    termination: {
      name: string;
    };
  };

  export function processRequestWithMiddlewares(
    request: FetchArgs
  ): FetchArgs | Termination {
    for (const middleware of CONFIG.requestMiddlewares || []) {
      try {
        const middlewareResponse = middleware.fn(request);
        if (middlewareResponse === null) {
          return { termination: { name: middleware.name } };
        }
        request = middlewareResponse;
      } catch (e) {
        console.error("Request middleware error", e);
      }
    }
    return request;
  }

  export function processResponseWithMiddlewares<T extends FetchResponse<unknown, any>>(
    response: T
  ): T | Termination {
    for (const middleware of CONFIG.responseMiddlewares || []) {
      try {
        const middlewareResponse = middleware.fn(response);
        if (middlewareResponse === null) {
          return {
            status: 0,
            args: response.args,
            data: null,
            error: new Error(
              `Response terminated by middleware: ${middleware.name}`
            ),
          } as FetchResponseOfError as unknown as T;
        }
        response = middlewareResponse as T;
      } catch (e) {
        console.error("Response middleware error", e);
      }
    }
    return response;
  }

  export type FetchOptions = {
    method: string;
    headers: Headers;
    body?: any;
    redirect: RequestRedirect;
  };

  export type FetchArgs = {
    url: string;
    options: FetchOptions;
  }

  export async function fetchJson<T extends FetchResponse<unknown, number>>(
    args: FetchArgs
  ): Promise<T> {
    const errorResponse = (error: StandardError, status: number, args: any) => {
      const errorResponse = {
        status: status as ErrorStatuses,
        args,
        data: null,
        error,
      } satisfies FetchResponse<T>;

      return processResponseWithMiddlewares(errorResponse) as unknown as T;
    };

    const errorStatus = (args: any) => {
      const errorResponse = {
        status: 0,
        args,
        data: null,
        error: new Error("Network error"),
      } as FetchResponse<T, Error500s>;

      return processResponseWithMiddlewares(errorResponse) as unknown as T;
    };

    try {
      const fetchRequest = processRequestWithMiddlewares(args);

      if ("termination" in fetchRequest) {
        const terminationResponse = {
          status: 0,
          args,
          data: null,
          error: new Error(
            `Request terminated by middleware: ${fetchRequest.termination.name}`
          ),
        } as FetchResponse<T, Error500s>;

        return processResponseWithMiddlewares(
          terminationResponse
        ) as unknown as T;
      }

      const fetchResponse: Response = await fetch(fetchRequest.url, fetchRequest.options);
      const status = fetchResponse.status;
      try {
        const json = await fetchResponse.json();
        const response = {
          data: json,
          status: fetchResponse.status,
          args,
          error: null,
          responseHeaders: fetchResponse.headers,
        };
        return processResponseWithMiddlewares(response) as unknown as T;
      } catch (error) {
        return errorResponse(error as StandardError, status, args);
      }
    } catch {
      return errorStatus(args);
    }
  }

  export function getJwtKey(): string | null | undefined {
    if (typeof CONFIG.jwtKey === "function") {
      return CONFIG.jwtKey();
    }

    if (typeof CONFIG.jwtKey === "string") {
      return localStorage.getItem(CONFIG.jwtKey);
    }

    return undefined;
  } 
  
  
 function getApiRequestData<Type extends any>(
    requestContract: Type | undefined,
    isFormData: boolean = false
  ): FormData | Type | {} {
  
    if (!isFormData) {
      return requestContract !== undefined ? requestContract : {};
    }
  
    //multipart/form-data
    const formData = new FormData();
  
     if (requestContract) {
      Object.keys(requestContract).forEach(key => {
        const value = requestContract[key as keyof Type];
        const isKeyArrayAndValueIterable = key.endsWith('[]') && typeof (value as any)[Symbol.iterator] === 'function';
        const values = isKeyArrayAndValueIterable ? Array.from(value as Iterable<any>) : [value];
          for (const val of values) {
              if (val === undefined) {
                  continue;
              } else if (val === null) {
                  formData.append(key, '');
              } else if (val instanceof File) {
                  formData.append(key, val);
              } else if (typeof val === 'object' && val !== null) {
                  formData.append(key, JSON.stringify(val));
              } else {
                  formData.append(key, val as any);
              }
          }
      });
    }
  
    return formData;
  }

  
  function updateHeadersAndGetBody<TResponse extends FetchResponse<unknown, number>, TRequest>(
    request: TRequest,
    headers: Headers
  ) {
    updateHeaders(headers);
    if (request instanceof FormData) {
      headers.delete("Content-Type");
      return request;
    } else {      
      return JSON.stringify(request);
    }
  }
  
  function updateHeaders(headers: Headers) {
    if (!headers.has("Content-Type")) {
      headers.append("Content-Type", "application/json");
    }
    const token = getJwtKey();
    if (!headers.has("Authorization") && !!token) {
      headers.append("Authorization", token);
    }
  }

export function getQueryParamsString(paramsObject: ParamsObject = {}) {
	const queryString = Object.entries(paramsObject)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map(val => `${encodeURIComponent(key)}=${encodeURIComponent(
            val,
          )}`)
          .join('&');
      }
      // Handling non-array parameters
      return value !== undefined && value !== null 
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` 
        : '';
    })
    .filter(part => part !== '')
    .join("&");

	return queryString.length > 0 ? `?${queryString}` : '';
}

export function apiPost<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  
  const raw = updateHeadersAndGetBody(request, headers); 

  const requestOptions: FetchOptions = {
    method: "POST",
    headers,
    body: raw,
    redirect: "follow",
  };

  const maybeQueryString = getQueryParamsString(paramsObject);

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}

export type ParamsObject = {
  [key: string]: any;
};

export function apiGet<TResponse extends FetchResponse<unknown, number>>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  
  const maybeQueryString = getQueryParamsString(paramsObject);

  const requestOptions: FetchOptions = {
    method: "GET",
    headers,
    redirect: "follow",
  };

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}

export function apiPut<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);

  const raw = JSON.stringify(request);

  const requestOptions: FetchOptions = {
    method: "PUT",
    headers,
    body: raw,
    redirect: "follow",
  };

  const maybeQueryString = getQueryParamsString(paramsObject);

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}

export function apiDelete<TResponse extends FetchResponse<unknown, number>>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);

  const queryString = Object.entries(paramsObject)
    .filter(([_, val]) => val !== undefined && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";

  const requestOptions: FetchOptions = {
    method: "DELETE",
    headers,
    redirect: "follow",
  };

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}

export function apiPatch<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);

  const raw = JSON.stringify(request);

  const requestOptions: FetchOptions = {
    method: "PATCH",
    headers,
    body: raw,
    redirect: "follow",
  };
  const maybeQueryString = getQueryParamsString(paramsObject);

  return fetchJson<TResponse>({
    url: `${url}${maybeQueryString}`,
    options: requestOptions,
  });
}
// INFRASTRUCTURE END

export type ApiProblemDetails = {
	errors: { [key: string | number]: ErrorDetail[] };
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export type ErrorDetail = {
	code: ErrorCode;
	message?: string | null;
};

export enum ErrorCode {
	Unspecified = "Unspecified",
	OutOfRange = "OutOfRange",
	NotFound = "NotFound",
	Invalid = "Invalid",
	Forbidden = "Forbidden",
	TooManyRequests = "TooManyRequests",
	Conflict = "Conflict",
	NullOrEmpty = "NullOrEmpty",
	Unauthorized = "Unauthorized",
	ExternalProviderNotAvailable = "ExternalProviderNotAvailable"
};

export type ProblemDetails = {
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export type EntityListOfUserListItemDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: UserListItemDto[];
};

export type UserListItemDto = {
	userID: number;
	login: string;
	firstName: string;
	lastName: string;
	dateCreated: string;
};

export type UserDetailDto = {
	userID: number;
	login: string;
	email: string;
	firstName?: string | null;
	lastName?: string | null;
	lastSignInDate?: string | null;
	phone?: string | null;
	userIdentityProviders: IdentityProvider[];
	profilePicture?: string | null;
	status: UserStatus;
};

export enum IdentityProvider {
	Application = "Application",
	Google = "Google",
	BankID = "BankID"
};

export enum UserStatus {
	Unknown = "Unknown",
	Active = "Active",
	WaitingForEmailConfirmation = "WaitingForEmailConfirmation"
};

export type AccountsResponse = {
	pageNumber: number;
	pageCount: number;
	pageSize: number;
	nextPage?: number | null;
	accounts: AccountDto[];
};

export type AccountDto = {
	id: string;
	identification: IdentificationDto;
	currency: string;
	servicer: ServicerDto;
	nameI18N: string;
	productI18N: string;
	ownersNames: string[];
	relationship: RelationshipDto;
	suitableScope: any;
};

export type IdentificationDto = {
	iban: string;
	other: string;
};

export type ServicerDto = {
	bankCode: string;
	countryCode: string;
	bic: string;
};

export type RelationshipDto = {
	isOwner: boolean;
};

export enum PSD2Environment {
	CSOB_SANDBOX = "CSOB_SANDBOX",
	CSOB_PROD = "CSOB_PROD",
	CESKA_SPORITELNA_SANDBOX = "CESKA_SPORITELNA_SANDBOX",
	CESKA_SPORITELNA_PROD = "CESKA_SPORITELNA_PROD"
};

export type AuthorizeDto = {
	url: string;
};

export type OneClickAndDoneResult = {
	redirectUrl: string;
};

export type TokenDto = {
	accessToken: string;
	refreshToken: string;
};

export type PaymentInitiationResponse = {
	paymentIdentification: PaymentIdentification;
	paymentTypeInformation: PaymentTypeInformation;
	amount: Amount;
	requestedExecutionDate: string;
	debtorAccount: Account;
	creditorAccount: Account;
	remittanceInformation: RemittanceInformation;
	signInfo: SignInfo;
	instructionStatus: string;
};

export type PaymentIdentification = {
	instructionIdentification: string;
	transactionIdentification: string;
};

export type PaymentTypeInformation = {
	instructionPriority: string;
	serviceLevel: ServiceLevel;
};

export type ServiceLevel = {
	code: string;
};

export type Amount = {
	instructedAmount: InstructedAmount;
};

export type InstructedAmount = {
	value: number;
	currency: string;
};

export type Account = {
	identification: Identification;
};

export type Identification = {
	iban: string;
};

export type RemittanceInformation = {
	structured: Structured;
	unstructured: string;
};

export type Structured = {
	creditorReferenceInformation: CreditorReferenceInformation;
};

export type CreditorReferenceInformation = {
	reference: string[];
};

export type SignInfo = {
	signId: string;
	hash: string;
	state: string;
};

export type PaymentInitiationRequest = {
	instructionPriority: string;
	amount: number;
	currency: string;
	requestedExecutionDate: string;
	chargeBearer: string;
	debtorAccountIban: string;
	creditorName: string;
	creditorStreetName: string;
	creditorBuildingNumber: string;
	creditorPostCode: string;
	creditorTownName: string;
	creditorCountry: string;
	creditorAccountIban: string;
	remittanceInformationUnstructured: string;
};

export type AuthorizationResponse = {
	validTo: string;
	signingUrl: string;
	poll: PollInfo;
	signInfo: SignInfo;
};

export type PollInfo = {
	url: string;
	id: string;
	interval: number;
};

export type PaymentStatusResponse = {
	signId: string;
	status: PaymentInstructionStatus;
	processedDateStarted?: string | null;
	processedDateFinished?: string | null;
};

export enum PaymentInstructionStatus {
	None = "None",
	AcceptedTechnicalValidation = "AcceptedTechnicalValidation",
	AcceptedCustomerProfile = "AcceptedCustomerProfile",
	AcceptedSettlementCompleted = "AcceptedSettlementCompleted",
	Rejected = "Rejected"
};

export type SaveUserNotificationSettingsCommandResult = {};

export type UserNotificationUpdateRequest = {
	isEmailNotificationActive: boolean;
};

export type GetCodeListCollectionQueryResult = {
	countries: CodeListItemDto[];
};

export type CodeListItemDto = {
	id: number;
	code: string;
	name: string;
};

export type SignUpCommandResult = {};

export type ApiProblemDetailsOfSignUpErrorStatus = {
	errors: { [key: string | number]: ErrorDetail[] };
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum SignUpErrorStatus {
	UserExists = "UserExists"
};

export type ApiProblemDetailsOfPasswordChangeResultStatus = {
	errors: { [key: string | number]: ErrorDetail[] };
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum PasswordChangeResultStatus {
	PasswordNotMatch = "PasswordNotMatch",
	NullOrEmpty = "NullOrEmpty",
	TooShort = "TooShort",
	Success = "Success"
};

export type SignUpCommand = {
	login: string;
	password: string;
};

export type SignInResult = {
	userInfo: UserInfo;
	isFirstSignIn: boolean;
};

export type UserInfo = {
	token: string;
	login: string;
	firstName: string;
	lastName: string;
	userIdentityProviders: IdentityProvider[];
	accessRightCodes: string[];
	profilePicture?: string | null;
};

export type ApiProblemDetailsOfAuthError = {
	errors: { [key: string | number]: ErrorDetail[] };
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum AuthError {
	ApplicationVerification = "ApplicationVerification",
	InvalidCredentials = "InvalidCredentials",
	AccountIsDisabled = "AccountIsDisabled",
	PhoneNumberNotFound = "PhoneNumberNotFound",
	SmsSendingFailed = "SmsSendingFailed",
	InvalidToken = "InvalidToken",
	AccountWaitingForEmailConfirmation = "AccountWaitingForEmailConfirmation"
};

export type SignInCommand = {
	login: string;
	password: string;
};

export type EmailVerificationCommandResult = {
	signInResult: SignInResult;
};

export type ApiProblemDetailsOfEmailVerificationCommandResultStatus = {
	errors: { [key: string | number]: ErrorDetail[] };
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum EmailVerificationCommandResultStatus {
	TokenNotFound = "TokenNotFound",
	UserNotFound = "UserNotFound"
};

export type EmailVerificationCommand = {
	token: string;
};

export type ResendVerificationEmailCommandResult = {};

export type ResendVerificationEmailCommand = {
	email: string;
};

export type SsoSignInRequest = {
	token: string;
	identityProvider: IdentityProvider;
};

export type ResetPasswordCommandResult = {};

export type ApiProblemDetailsOfResetPasswordCommandResultStatus = {
	errors: { [key: string | number]: ErrorDetail[] };
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum ResetPasswordCommandResultStatus {
	Fail = "Fail"
};

export type ResetPasswordCommand = {
	login: string;
};

export type SetPasswordCommandResult = {
	signInResult: SignInResult;
};

export type ApiProblemDetailsOfSetPasswordCommandStatus = {
	errors: { [key: string | number]: ErrorDetail[] };
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum SetPasswordCommandStatus {
	TokenNotFound = "TokenNotFound"
};

export type SetPasswordCommand = {
	token: string;
	password: string;
};

export type GetUsersFetchResponse = 
| FetchResponse<EntityListOfUserListItemDto, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getUsersPath = () => `/api/v1/users`;

export const getUsers = (offset?: number, limit?: number, headers = new Headers()):
  Promise<GetUsersFetchResponse> => {
    const queryParams = {
      "offset": offset,
      "limit": limit
    }
    return apiGet(`${getApiUrl()}${getUsersPath()}`, headers, queryParams) as Promise<GetUsersFetchResponse>;
}

export type GetUsersUserIDFetchResponse = 
| FetchResponse<UserDetailDto, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getUsersUserIDPath = (userID: number) => `/api/v1/users/${userID}`;

export const getUsersUserID = (userID: number, headers = new Headers()):
  Promise<GetUsersUserIDFetchResponse> => {
    return apiGet(`${getApiUrl()}${getUsersUserIDPath(userID)}`, headers, {}) as Promise<GetUsersUserIDFetchResponse>;
}

export type GetPsd2AispAccountsFetchResponse = 
| FetchResponse<AccountsResponse, 200> 
| FetchResponse<ProblemDetails, 400> 
| FetchResponse<ProblemDetails, 401> 
| FetchResponse<ProblemDetails, 403> 
| FetchResponse<ProblemDetails, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getPsd2AispAccountsPath = () => `/api/v1/psd2/aisp/accounts`;

export const getPsd2AispAccounts = (accessToken?: string, environment?: PSD2Environment, page?: number, pageSize?: number, sortBy?: string, sortOrder?: string, headers = new Headers()):
  Promise<GetPsd2AispAccountsFetchResponse> => {
    const queryParams = {
      "accessToken": accessToken,
      "environment": environment,
      "page": page,
      "pageSize": pageSize,
      "sortBy": sortBy,
      "sortOrder": sortOrder
    }
    return apiGet(`${getApiUrl()}${getPsd2AispAccountsPath()}`, headers, queryParams) as Promise<GetPsd2AispAccountsFetchResponse>;
}

export type GetPsd2AuthAuthorizeFetchResponse = 
| FetchResponse<AuthorizeDto, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getPsd2AuthAuthorizePath = () => `/api/v1/psd2/auth/authorize`;

export const getPsd2AuthAuthorize = (environment?: PSD2Environment, isOneClickAndDone?: boolean, headers = new Headers()):
  Promise<GetPsd2AuthAuthorizeFetchResponse> => {
    const queryParams = {
      "environment": environment,
      "isOneClickAndDone": isOneClickAndDone
    }
    return apiGet(`${getApiUrl()}${getPsd2AuthAuthorizePath()}`, headers, queryParams) as Promise<GetPsd2AuthAuthorizeFetchResponse>;
}

export type GetPsd2AuthCallbackFetchResponse = 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getPsd2AuthCallbackPath = () => `/api/v1/psd2/auth/callback`;

export const getPsd2AuthCallback = (code?: string, state?: string, headers = new Headers()):
  Promise<GetPsd2AuthCallbackFetchResponse> => {
    const queryParams = {
      "code": code,
      "state": state
    }
    return apiGet(`${getApiUrl()}${getPsd2AuthCallbackPath()}`, headers, queryParams) as Promise<GetPsd2AuthCallbackFetchResponse>;
}

export type GetPsd2AuthOneClickAndDoneFetchResponse = 
| FetchResponse<OneClickAndDoneResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getPsd2AuthOneClickAndDonePath = () => `/api/v1/psd2/auth/one-click-and-done`;

export const getPsd2AuthOneClickAndDone = (refreshToken?: string | undefined | null, code?: string | undefined | null, env?: string, headers = new Headers()):
  Promise<GetPsd2AuthOneClickAndDoneFetchResponse> => {
    const queryParams = {
      "refreshToken": refreshToken,
      "code": code,
      "env": env
    }
    return apiGet(`${getApiUrl()}${getPsd2AuthOneClickAndDonePath()}`, headers, queryParams) as Promise<GetPsd2AuthOneClickAndDoneFetchResponse>;
}

export type GetPsd2AuthFetchResponse = 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getPsd2AuthPath = () => `/api/v1/psd2/auth`;

export const getPsd2Auth = (accessToken?: string, refreshToken?: string, environment?: PSD2Environment, headers = new Headers()):
  Promise<GetPsd2AuthFetchResponse> => {
    const queryParams = {
      "accessToken": accessToken,
      "refreshToken": refreshToken,
      "environment": environment
    }
    return apiGet(`${getApiUrl()}${getPsd2AuthPath()}`, headers, queryParams) as Promise<GetPsd2AuthFetchResponse>;
}

export type GetPsd2AuthRefreshTokenFetchResponse = 
| FetchResponse<TokenDto, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getPsd2AuthRefreshTokenPath = () => `/api/v1/psd2/auth/refresh-token`;

export const getPsd2AuthRefreshToken = (refreshToken?: string, environment?: PSD2Environment, headers = new Headers()):
  Promise<GetPsd2AuthRefreshTokenFetchResponse> => {
    const queryParams = {
      "refreshToken": refreshToken,
      "environment": environment
    }
    return apiGet(`${getApiUrl()}${getPsd2AuthRefreshTokenPath()}`, headers, queryParams) as Promise<GetPsd2AuthRefreshTokenFetchResponse>;
}

export type PostPsd2PispInitiatePaymentFetchResponse = 
| FetchResponse<PaymentInitiationResponse, 201> 
| FetchResponse<ProblemDetails, 400> 
| FetchResponse<ProblemDetails, 401> 
| FetchResponse<ProblemDetails, 403> 
| FetchResponse<ProblemDetails, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postPsd2PispInitiatePaymentPath = (accessToken?: string, refreshToken?: string, environment?: PSD2Environment) => `/api/v1/psd2/pisp/initiate-payment`;

export const postPsd2PispInitiatePayment = (requestContract: PaymentInitiationRequest, accessToken?: string, refreshToken?: string, environment?: PSD2Environment, headers = new Headers()):
  Promise<PostPsd2PispInitiatePaymentFetchResponse> => {
    const queryParams = {
      "accessToken": accessToken,
      "refreshToken": refreshToken,
      "environment": environment
    };
    const requestData = getApiRequestData<PaymentInitiationRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postPsd2PispInitiatePaymentPath()}`, requestData, headers, queryParams) as Promise<PostPsd2PispInitiatePaymentFetchResponse>;
}

export type GetPsd2PispAuthorizePaymentFetchResponse = 
| FetchResponse<AuthorizationResponse, 200> 
| FetchResponse<ProblemDetails, 400> 
| FetchResponse<ProblemDetails, 401> 
| FetchResponse<ProblemDetails, 403> 
| FetchResponse<ProblemDetails, 404> 
| FetchResponse<ProblemDetails, 405> 
| FetchResponse<ProblemDetails, 412> 
| FetchResponse<ProblemDetails, 429> 
| FetchResponse<ProblemDetails, 500> 
| FetchResponse<ProblemDetails, 503> 
| ErrorResponse;

export const getPsd2PispAuthorizePaymentPath = () => `/api/v1/psd2/pisp/authorize-payment`;

export const getPsd2PispAuthorizePayment = (signId?: string, hash?: string, accessToken?: string, refreshToken?: string, environment?: PSD2Environment, headers = new Headers()):
  Promise<GetPsd2PispAuthorizePaymentFetchResponse> => {
    const queryParams = {
      "signId": signId,
      "hash": hash,
      "accessToken": accessToken,
      "refreshToken": refreshToken,
      "environment": environment
    }
    return apiGet(`${getApiUrl()}${getPsd2PispAuthorizePaymentPath()}`, headers, queryParams) as Promise<GetPsd2PispAuthorizePaymentFetchResponse>;
}

export type GetPsd2PispCallbackFetchResponse = 
| FetchResponse<void, 200> 
| FetchResponse<ProblemDetails, 400> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getPsd2PispCallbackPath = () => `/api/v1/psd2/pisp/callback`;

export const getPsd2PispCallback = (signId?: string, accessToken?: string, refreshToken?: string, environment?: PSD2Environment, headers = new Headers()):
  Promise<GetPsd2PispCallbackFetchResponse> => {
    const queryParams = {
      "signId": signId,
      "accessToken": accessToken,
      "refreshToken": refreshToken,
      "environment": environment
    }
    return apiGet(`${getApiUrl()}${getPsd2PispCallbackPath()}`, headers, queryParams) as Promise<GetPsd2PispCallbackFetchResponse>;
}

export type GetPsd2PispPaymentStatusFetchResponse = 
| FetchResponse<PaymentStatusResponse, 200> 
| FetchResponse<ProblemDetails, 400> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getPsd2PispPaymentStatusPath = () => `/api/v1/psd2/pisp/payment-status`;

export const getPsd2PispPaymentStatus = (signId?: string, environment?: PSD2Environment, headers = new Headers()):
  Promise<GetPsd2PispPaymentStatusFetchResponse> => {
    const queryParams = {
      "signId": signId,
      "environment": environment
    }
    return apiGet(`${getApiUrl()}${getPsd2PispPaymentStatusPath()}`, headers, queryParams) as Promise<GetPsd2PispPaymentStatusFetchResponse>;
}

export type PutNotificationsUserSettingsFetchResponse = 
| FetchResponse<SaveUserNotificationSettingsCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const putNotificationsUserSettingsPath = () => `/api/v1/notifications/user-settings`;

export const putNotificationsUserSettings = (requestContract: UserNotificationUpdateRequest, headers = new Headers()):
  Promise<PutNotificationsUserSettingsFetchResponse> => {
    const requestData = getApiRequestData<UserNotificationUpdateRequest>(requestContract, false);

    return apiPut(`${getApiUrl()}${putNotificationsUserSettingsPath()}`, requestData, headers) as Promise<PutNotificationsUserSettingsFetchResponse>;
}

export type GetCodeListsFetchResponse = 
| FetchResponse<GetCodeListCollectionQueryResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getCodeListsPath = () => `/api/v1/code-lists`;

export const getCodeLists = (headers = new Headers()):
  Promise<GetCodeListsFetchResponse> => {
    return apiGet(`${getApiUrl()}${getCodeListsPath()}`, headers, {}) as Promise<GetCodeListsFetchResponse>;
}

export type PostCacheRefreshFetchResponse = 
| FetchResponse<void, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 403> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postCacheRefreshPath = () => `/api/v1/cache/refresh`;

export const postCacheRefresh = (body: string, headers = new Headers()):
  Promise<PostCacheRefreshFetchResponse> => {
    const requestData = getApiRequestData<string>(body, false);

    return apiPost(`${getApiUrl()}${postCacheRefreshPath()}`, requestData, headers) as Promise<PostCacheRefreshFetchResponse>;
}

export type PostAuthSignUpFetchResponse = 
| FetchResponse<SignUpCommandResult, 201> 
| FetchResponse<ApiProblemDetailsOfPasswordChangeResultStatus, 400> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfSignUpErrorStatus, 409> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthSignUpPath = () => `/api/v1/auth/sign-up`;

export const postAuthSignUp = (requestContract: SignUpCommand, headers = new Headers()):
  Promise<PostAuthSignUpFetchResponse> => {
    const requestData = getApiRequestData<SignUpCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthSignUpPath()}`, requestData, headers) as Promise<PostAuthSignUpFetchResponse>;
}

export type PostAuthSignInFetchResponse = 
| FetchResponse<SignInResult, 200> 
| FetchResponse<ApiProblemDetailsOfAuthError, 400> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthSignInPath = () => `/api/v1/auth/sign-in`;

export const postAuthSignIn = (requestContract: SignInCommand, headers = new Headers()):
  Promise<PostAuthSignInFetchResponse> => {
    const requestData = getApiRequestData<SignInCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthSignInPath()}`, requestData, headers) as Promise<PostAuthSignInFetchResponse>;
}

export type PostAuthEmailVerificationFetchResponse = 
| FetchResponse<EmailVerificationCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfEmailVerificationCommandResultStatus, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthEmailVerificationPath = () => `/api/v1/auth/email-verification`;

export const postAuthEmailVerification = (requestContract: EmailVerificationCommand, headers = new Headers()):
  Promise<PostAuthEmailVerificationFetchResponse> => {
    const requestData = getApiRequestData<EmailVerificationCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthEmailVerificationPath()}`, requestData, headers) as Promise<PostAuthEmailVerificationFetchResponse>;
}

export type PostAuthEmailVerificationSendFetchResponse = 
| FetchResponse<ResendVerificationEmailCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthEmailVerificationSendPath = () => `/api/v1/auth/email-verification/send`;

export const postAuthEmailVerificationSend = (requestContract: ResendVerificationEmailCommand, headers = new Headers()):
  Promise<PostAuthEmailVerificationSendFetchResponse> => {
    const requestData = getApiRequestData<ResendVerificationEmailCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthEmailVerificationSendPath()}`, requestData, headers) as Promise<PostAuthEmailVerificationSendFetchResponse>;
}

export type PostAuthSsoFetchResponse = 
| FetchResponse<SignInResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfAuthError, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthSsoPath = () => `/api/v1/auth/sso`;

export const postAuthSso = (requestContract: SsoSignInRequest, headers = new Headers()):
  Promise<PostAuthSsoFetchResponse> => {
    const requestData = getApiRequestData<SsoSignInRequest>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthSsoPath()}`, requestData, headers) as Promise<PostAuthSsoFetchResponse>;
}

export type PostAuthPasswordResetFetchResponse = 
| FetchResponse<ResetPasswordCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfResetPasswordCommandResultStatus, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthPasswordResetPath = () => `/api/v1/auth/password-reset`;

export const postAuthPasswordReset = (requestContract: ResetPasswordCommand, headers = new Headers()):
  Promise<PostAuthPasswordResetFetchResponse> => {
    const requestData = getApiRequestData<ResetPasswordCommand>(requestContract, false);

    return apiPost(`${getApiUrl()}${postAuthPasswordResetPath()}`, requestData, headers) as Promise<PostAuthPasswordResetFetchResponse>;
}

export type PutAuthPasswordFetchResponse = 
| FetchResponse<SetPasswordCommandResult, 200> 
| FetchResponse<ApiProblemDetailsOfPasswordChangeResultStatus, 400> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfSetPasswordCommandStatus, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const putAuthPasswordPath = () => `/api/v1/auth/password`;

export const putAuthPassword = (requestContract: SetPasswordCommand, headers = new Headers()):
  Promise<PutAuthPasswordFetchResponse> => {
    const requestData = getApiRequestData<SetPasswordCommand>(requestContract, false);

    return apiPut(`${getApiUrl()}${putAuthPasswordPath()}`, requestData, headers) as Promise<PutAuthPasswordFetchResponse>;
}

export type GetAuthUserinfoFetchResponse = 
| FetchResponse<UserInfo, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getAuthUserinfoPath = () => `/api/v1/auth/userinfo`;

export const getAuthUserinfo = (headers = new Headers()):
  Promise<GetAuthUserinfoFetchResponse> => {
    return apiGet(`${getApiUrl()}${getAuthUserinfoPath()}`, headers, {}) as Promise<GetAuthUserinfoFetchResponse>;
}
