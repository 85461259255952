import { Typography } from "@mui/material";
import { AuthenticatedLayout } from "Shared/Layouts/AuthenticatedLayout";
import { UsersTable } from "Users/Components/UsersTable";

export const UsersPage: React.FunctionComponent = () => {
	return (
		<AuthenticatedLayout title="Uživatelé">
			<Typography variant="h1">Uživatelé</Typography>
			<UsersTable />
		</AuthenticatedLayout>
	);
};
