import { useMutation, useQueryClient } from "@tanstack/react-query";
import { USER_INFO_QUERY_KEY } from "Auth/Api/Queries/useUserInfoQuery";
import { JWT_KEY } from "Auth/Models/AuthConstants";
import {
	type FetchResponseOfError,
	postAuthSignIn,
} from "Infrastructure/Api/Api";
import { ApiCallError } from "Infrastructure/Api/ApiUtils";

export function useSignInMutation() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: postAuthSignIn,
		onSuccess: (data) => {
			if (data.status === 400) {
				throw new ApiCallError(data as unknown as FetchResponseOfError);
			}

			if (data.status === 200) {
				console.log("Setting token");

				localStorage.setItem(JWT_KEY, `Bearer ${data.data.userInfo.token}`);
			}
			console.log("Invalidating queries");

			queryClient.invalidateQueries({ queryKey: [USER_INFO_QUERY_KEY] });
		},
	});
}
