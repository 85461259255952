export const AppRouting = {
	Error403: {
		path: () => "/403",
	},
	Error404: {
		path: () => "/404",
	},
	SignIn: {
		path: () => "/",
	},
	Dashboard: {
		path: () => "/dashboard",
	},
	Users: {
		path: () => "/users",
	},
	UserDetail: {
		route: "/users/:id",
		path: (id: number) => `/users/${id}`,
	},
	Psd2Callback: {
		route: "/psd2/callback",
		path: () => "/psd2/callback",
	},
	PispCallback: {
		route: "/psd2/pisp-callback",
		path: () => "/psd2/pisp-callback",
	},
} as const;

type RouteObject<T extends any[] = any[]> = {
	route?: string;
	path: (...args: T) => string;
};

export function getRoute<T extends RouteObject>(routeObj: T): string {
	return routeObj.route ?? routeObj.path();
}

export function resolveStartPage(
	user: unknown | null,
	unauthenticatedUrl: string | null,
) {
	if (user === null) {
		return AppRouting.SignIn.path();
	}

	if (unauthenticatedUrl) {
		return unauthenticatedUrl;
	}
	return AppRouting.Dashboard.path();
}

export function getPathnameAndSearch(location: {
	pathname: string;
	search: string;
}) {
	return `${location.pathname}${location.search}`;
}
