import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import { AppRouting } from "Infrastructure/Utils/UrlUtils";
import { UnstyledLink } from "Shared/Routing/UnstyledLink";
import { useUserDetailQuery } from "Users/Api/Queries/useUserDetailQuery";
type Props = { id: number };

export const UserDetail: React.FunctionComponent<Props> = (props) => {
	const { id } = props;

	const { data, isLoading, error } = useUserDetailQuery(id);

	if (isLoading) {
		return <>...</>;
	}

	if (data?.status !== 200 || !!error) {
		return <>Uživatel nenalezen: {error?.message}</>;
	}

	return (
		<>
			<UnstyledLink to={AppRouting.Users.path()}>
				<ArrowBackIcon />
			</UnstyledLink>
			<div>
				<Typography variant="h1">{data?.data.login}</Typography>
				<Typography>{data?.data.email}</Typography>
				<Typography>{data?.data.phone}</Typography>
				<Typography>
					{`${data?.data.firstName} ${data?.data.lastName}`}
				</Typography>
			</div>
		</>
	);
};
