import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
	Box,
	Button,
	Collapse,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import type React from "react";
import { useState } from "react";

interface TokenDisplayProps {
	accessToken: string | null;
	refreshToken: string | null;
}

const TokenField: React.FC<{ label: string; token: string | null }> = ({
	label,
	token,
}) => {
	const [showToken, setShowToken] = useState(false);

	const handleCopy = () => {
		if (token) {
			navigator.clipboard.writeText(token);
		}
	};

	if (!token) return null;

	return (
		<Box sx={{ mb: 1 }}>
			<Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
				<Typography variant="body2" sx={{ mr: 1 }}>
					{label}:
				</Typography>
				<CheckCircleIcon color="success" fontSize="small" sx={{ mr: 1 }} />
				<Button
					size="small"
					variant="outlined"
					onClick={() => setShowToken(!showToken)}
				>
					{showToken ? "Hide" : "Show"}
				</Button>
				<IconButton size="small" onClick={handleCopy} sx={{ ml: 1 }}>
					<ContentCopyIcon fontSize="small" />
				</IconButton>
			</Box>
			<Collapse in={showToken}>
				<TextField
					value={token}
					fullWidth
					size="small"
					InputProps={{
						readOnly: true,
					}}
					variant="outlined"
					multiline
					rows={2}
				/>
			</Collapse>
		</Box>
	);
};

export const TokenDisplay: React.FC<TokenDisplayProps> = ({
	accessToken,
	refreshToken,
}) => {
	if (!accessToken && !refreshToken) {
		return null;
	}

	return (
		<Box>
			<Typography variant="subtitle1" gutterBottom>
				Tokens
			</Typography>
			<TokenField label="Access Token" token={accessToken} />
			<TokenField label="Refresh Token" token={refreshToken} />
		</Box>
	);
};
