import { PSD2Environment } from "Infrastructure/Api/Api";

export type EnvironmentConfig = {
	name: string;
};

export const environmentConfigs: Record<PSD2Environment, EnvironmentConfig> = {
	[PSD2Environment.CESKA_SPORITELNA_SANDBOX]: {
		name: "Česká spořitelna SANDBOX",
	},
	[PSD2Environment.CESKA_SPORITELNA_PROD]: { name: "Česká spořitelna PROD" },
	[PSD2Environment.CSOB_SANDBOX]: { name: "ČSOB SANDBOX" },
	[PSD2Environment.CSOB_PROD]: { name: "ČSOB PROD" },
};
