import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import { useSignInMutation } from "Auth/Api/Mutations/useSignInMutation";
import { BlFormInput } from "Forms/Components/BlFormInput";
import useHandleServerError from "Forms/Hooks/useHandleServerError";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { BlButton } from "Shared/Buttons/BlButton";
import type { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const SignInSchema = z.object({
	login: z.string().email(),
	password: z.string().min(3),
});

type SignInFormModel = z.infer<typeof SignInSchema>;

const PageResources = Resources.SignIn;

type Props = {};

export const SignInForm: FunctionComponent<Props> = () => {
	const form = useForm<SignInFormModel>({
		resolver: zodResolver(SignInSchema),
	});
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = form;
	console.log(errors);
	const { t } = useResource();

	const { mutate, error } = useSignInMutation();

	const submit = (data: SignInFormModel) => {
		mutate(data);
	};

	useHandleServerError({
		form,
		error,
		formKey: "login",
		resource: PageResources.Error,
	});

	return (
		<Box>
			<Typography variant="h1" mb={4}>
				{t(PageResources.Title)}
			</Typography>

			<form onSubmit={handleSubmit(submit)}>
				<BlFormInput
					control={control}
					name="login"
					autoComplete="login"
					errors={errors}
					label={t(PageResources.Login.Label)}
				/>

				<BlFormInput
					control={control}
					name="password"
					errors={errors}
					type="password"
					autoComplete="password"
					label={t(PageResources.Password.Label)}
				/>

				<Box m={2} />

				<BlButton type="submit" fullWidth color="primary">
					{t(PageResources.Button)}
				</BlButton>
			</form>
		</Box>
	);
};
