import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	type PSD2Environment,
	getPsd2AuthRefreshToken,
} from "Infrastructure/Api/Api";
import { AISP_ACCOUNTS_QUERY_KEY } from "Psd2/Api/Queries/useAispAccountsQuery";
import { tokenFamily } from "Psd2/Storage/TokenAtom";
import { useSetAtom } from "jotai";
import { toast } from "sonner";

const REFRESH_TOKEN_MUTATION_KEY = "REFRESH_TOKEN_MUTATION";

interface RefreshTokenParams {
	refreshToken: string;
	environment: PSD2Environment;
}

export function useRefreshTokenMutation(environment: PSD2Environment) {
	const queryClient = useQueryClient();
	const setTokens = useSetAtom(tokenFamily(environment));

	return useMutation({
		mutationKey: [REFRESH_TOKEN_MUTATION_KEY],
		mutationFn: ({ refreshToken, environment }: RefreshTokenParams) =>
			getPsd2AuthRefreshToken(refreshToken, environment),
		onSuccess: (response) => {
			const { data, status } = response;
			if (status === 200) {
				setTokens({
					accessToken: data.accessToken,
					refreshToken: data.refreshToken,
				});

				toast.success("Token refreshed");
			}

			queryClient.invalidateQueries({ queryKey: [AISP_ACCOUNTS_QUERY_KEY] });
		},
		onError: (error) => {
			console.error("Failed to refresh token:", error);
			toast.error("Failed to refresh token");
		},
	});
}
