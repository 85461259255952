import type { ResourceDictionary } from "Infrastructure/Translations/ResourceDictionary";
import translation from "zod-i18n-map/locales/cs/zod.json";

export const CzechResourcesDictionary: ResourceDictionary = {
	Common: {
		AppName: "Moone",
		Years: "{{count}} let",
		Years_one: "{{count}} rok",
		Years_two: "{{count}} roky",
		Years_few: "{{count}} roky",
		Years_other: "{{count}} let",
		YearsLabel: "let",
		YearsLabel_one: "rok",
		YearsLabel_few: "roky",
		YearsLabel_other: "let",
		Month: "měsíc",
		Months: "{{count}} měsíců",
		Months_one: "{{count}} měsíc",
		Months_two: "{{count}} měsíce",
		Months_few: "{{count}} měsíce",
		Months_other: "{{count}} měsíců",
		Continue: "Pokračovat",
		Step: "KROK {{actual}} Z {{count}}",
		TextCopied: "Text byl zkopírován do schránky.",
		Yes: "Ano",
		No: "Ne",
		Choose: "Vyberte",
		Download: "Stáhnout",
		Downloading: "Stahování",
		UnsupportedFileType: "Nepodporovaný typ souboru",
		Email: "E-mail",
		Password: "Heslo",
		ExampleLogin: "např. antonin.vomacka@email.cz",
		PiecesShort: "{{count, number}} ks",
		PiecesSuffix: "ks",
		Active: "Aktivní",
		Inactive: "Neaktivní",
		Actual: "Aktuální",
		Blocked: "Blokováno",
		Canceled: "Zrušeno",
		Cancel: "Zrušit",
		Close: "Zavřít",
		Confirm: "Potvrdit",
		Ended: "Ukončeno",
		All: "Vše",
		AllStatuses: "Všechny stavy",
		PrivacyPolicyLink: "Zásadách zpracování osobních údajů.",
		Error:
			"Něco se pokazilo. Omlouváme se za komplikace. Zkuste to prosím znovu za chvíli.",
		Save_Uppercase: "ULOŽIT",
		Save: "Uložit",
		Reset: "RESET",
		WithTax: "s DPH",
		WithoutTax: "bez DPH",
		Edit: "Upravit",
		Back: "Zpět",
		Delete: "Smazat",
		PreviousStep: "Předchozí krok",
		SaveAndClose: "Uložit a zavřít",
		SaveSuccess: "Uložení proběhlo úspěšně.",
		CompanyNumber: "IČ",
		TaxNumber: "DIČ",
		PersonalNumber: "RČ",
		ProcessedByRepresentative: "Zpracoval{{gender}} {{name}}",
		SendByRepresentative: "Odeslal{{gender}} {{name}}",
		SignOut: "Odhlásit se",
	},
	Errors: {
		ApplicationError: {
			Title: "Nastala chyba",
			Home: "Domů",
		},
		Error404: {
			Subtitle: "Stránka nebyla nalezena",
			Home: "Domů",
		},
		Error403: {
			Subtitle: "K zobrazení této stránky nemáte přístupová práva",
		},
		Error500: "Při zpracování požadavku nastala chyba.",
	},
	SignIn: {
		Error: {
			General: "Nastala chyba",
			AccountIsDisabled: "Účet je zablokován",
			InvalidCredentials: "Neplatné přihlašovací údaje",
			AccountWaitingForEmailConfirmation: "Účet čeká na potvrzení e-mailu",
			ApplicationVerification: "Neplatné přihlašovací údaje",
			InvalidToken: "Neplatný token",
			PhoneNumberNotFound: "Telefonní číslo nebylo nalezeno",
			SmsSendingFailed: "Odeslání SMS se nezdařilo",
		},
		Title: "Přihlášení",
		Login: {
			Label: "Přihlášení",
		},
		Password: {
			Label: "Heslo",
		},
		Button: "Přihlásit",
	},
	SignUp: {
		Error: {
			General: "Nastala chyba",
			UserExists: "Účet již existuje",
			WeakPassword:
				"Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
		},
	},
	Validation: {
		Required: "Vyplňte pole",
		InvalidEmail: "E-mail je nevalidní",
		AlphabetsOnly: "Pro toto pole jsou povoleny pouze písmenka",
		DigitsOnly: "Může obsahovat pouze číslice",
		InvalidPhone: "Telefonní číslo je nevalidní",
		InvalidFormat: "Neplatný formát",
		InvalidBankAccount: "Neplatné číslo účtu",
		InvalidPersonalIdentificationNumber: "Neplatné rodné číslo",
		InvalidTaxNumber: "Neplatné DIČ",
		InvalidCompanyNumber: "Neplatné IČO",
		IsTrue: "Musí být zaškrtnuto",
		Number: {
			Integer: "Musí být celé číslo",
			Min: "Musí být větší nebo rovno {{count}}",
			MoreThen: "Musí být větší než {{count}}",
		},
		String: {
			Min: "Musí být alespoň {{count}} znaků dlouhé.",
			Max: "Nesmí být delší než {{count}} znaků.",
			StrictLength: "Musí obsahovat {{count}} znaků",
			InvalidFormat: "Neplatný formát",
		},
		Array: {
			Min: "Musí obsahovat alespoň {{count}} položku.",
			Min_one: "Musí být vybrána alespoň {{count}} položka.",
			Min_few: "Musí být vybrány alespoň {{count}} položky.",
			Min_other: "Musí být vybráno alespoň {{count}} položek.",
		},
	},
	SetPassword: {
		Error: {
			General: "Nastala chyba",
			TokenNotFound: "Token nebyl nalezen",
			WeakPassword: "Heslo je příliš slabé",
		},
	},
	BlDatePicker: {
		Locales: {
			One: "den",
			Two: "dny",
			Few: "dny",
			Other: "dnů",
			Many: "dnů",
			Zero: "dnů",
		},
		Placeholder: "Vyberte datum",
		Presets: {
			Today: "Dnes",
			Yesterday: "Včera",
			Last7Days: "Posledních 7 dní",
			Last30Days: "Posledních 30 dní",
			ThisMonth: "Tento měsíc",
			LastMonth: "Minulý měsíc",
		},
	},
	Autocomplete: {
		ClearText: "Smazat",
		CloseText: "Zavřít",
		OpenText: "Otevřít",
		NoOptionsText: "Žádné možnosti",
		LoadingText: "Načítání...",
	},
	Table: {
		NoData: "Žádná data",
		NextPage: "Další strana",
		PrevPage: "Předchozí strana",
		RowsPerPage: "Řádků na stránku",
	},
	zod: translation,
};
