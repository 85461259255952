import type { ApiProblemDetails } from "Infrastructure/Api/Api";
import { ApiCallError } from "Infrastructure/Api/ApiUtils";
import {
	Resources,
	useResource,
	useServerError,
} from "Infrastructure/Translations/Resources";
import { useCallback, useEffect, useRef } from "react";
import type { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";
import { toast } from "sonner";

type Resource = {
	General: string;
	[key: string]: string;
};

type KeyMap<T extends FieldValues> = Partial<Record<FieldPath<T>, string>>;

type UseHandleErrorsProps<T extends FieldValues> = {
	error: Error | null | undefined;
	form: UseFormReturn<T, any>;
	formKey: FieldPath<T>;
	keyMap?: KeyMap<T>;
	resource: Resource;
};

const useHandleServerError = <T extends FieldValues>({
	error,
	formKey,
	form,
	resource,
}: UseHandleErrorsProps<T>) => {
	const errorKeyRef = useRef<(FieldPath<T> | null)[]>([]);
	const { t } = useResource();

	const {
		setError,
		clearErrors,
		watch,
		formState: { errors },
	} = form;
	const { translateError } = useServerError(resource, resource.General);

	const formKeys = Object.keys(watch());

	const handleErrors = useCallback(() => {
		if (error) {
			const errorMessages = transformMessages(
				t,
				error,
				formKey,
				translateError,
			);

			// First, clear all previously set server errors
			for (const k of errorKeyRef.current) {
				if (k) {
					clearErrors(k);
				}
			}

			// Reset the error keys tracking
			errorKeyRef.current = [];

			// Then set new server errors
			if (errorMessages.length === 0) {
				setError(formKey, {
					type: "server",
					message: t(Resources.Errors.ApplicationError.Title),
				});
			} else {
				for (const errorMessage of errorMessages) {
					const { messages, key: errorKey } = errorMessage;
					const message = messages.join("\n");
					const k = errorKey ?? formKey;

					const prevErrorMessage = errors[k]?.message as string;
					if (!formKeys.includes(k)) {
						toast.error(message, { duration: Number.POSITIVE_INFINITY });
						continue;
					}

					if (prevErrorMessage && prevErrorMessage !== message) {
						setError(k as any, {
							type: "server",
							message: `${prevErrorMessage} ${message}`,
						});
					} else {
						setError(k as any, {
							type: "server",
							message,
						});
					}
					// Track the keys for which server errors are set
					errorKeyRef.current.push(k as any);
				}
			}
		} else {
			// If there's no server error, clear all previously set server errors
			for (const k of errorKeyRef.current) {
				if (k) {
					clearErrors(k);
				}
			}
			// Reset the error keys tracking
			errorKeyRef.current = [];
		}
	}, [
		error,
		errors,
		t,
		formKey,
		setError,
		clearErrors,
		formKeys.includes,
		translateError,
	]);
	const prevServerErrorRef = useRef(error);

	useEffect(() => {
		if (prevServerErrorRef.current !== error) {
			handleErrors();
			prevServerErrorRef.current = error;
		}
	}, [error, handleErrors]);
};

const transformMessages = (
	t: ReturnType<typeof useResource>["t"],
	error: Error,
	formKey: FieldPath<FieldValues>,
	translateError: ReturnType<typeof useServerError>["translateError"],
) => {
	const fallbackErrorMessage = [
		{
			key: formKey,
			messages: [t(Resources.Errors.ApplicationError.Title)],
		},
	];

	if (error instanceof ApiCallError && !!error.data) {
		const exception = error.data.data as unknown as ApiProblemDetails;

		if (!!exception.errors && Object.keys(exception.errors).length > 0) {
			const errorMessages = Object.entries(exception.errors).map(
				([key, value]) => {
					const messages = value
						.map((v) => translateError(v.message))
						.filter(Boolean)
						.map((v) => v!);

					return {
						key,
						messages,
					};
				},
			);

			return errorMessages.length > 0 ? errorMessages : fallbackErrorMessage;
		}
	}
	return fallbackErrorMessage;
};

export default useHandleServerError;
