import { Grid, Typography } from "@mui/material";
import { PSD2Environment } from "Infrastructure/Api/Api";
import { BankEnvironment } from "Psd2/Components/BankEnvironment";
import { AuthenticatedLayout } from "Shared/Layouts/AuthenticatedLayout";
import type React from "react";

// Define allowed bank environments
const allowedEnvironments = [
	PSD2Environment.CESKA_SPORITELNA_SANDBOX,
	PSD2Environment.CESKA_SPORITELNA_PROD,
	PSD2Environment.CSOB_SANDBOX,
];

export const DashboardPage: React.FunctionComponent = () => {
	return (
		<AuthenticatedLayout title="Dashboard">
			<Typography variant="h1" sx={{ mb: 4 }}>
				Dashboard
			</Typography>
			<Grid container spacing={3}>
				{allowedEnvironments.map((env) => (
					<Grid item xs={12} key={env}>
						<BankEnvironment environment={env} />
					</Grid>
				))}
			</Grid>
		</AuthenticatedLayout>
	);
};
