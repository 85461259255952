import { AuthenticatedLayout } from "Shared/Layouts/AuthenticatedLayout";
import { UserDetail } from "Users/Components/UserDetail";
import { useParams } from "react-router-dom";

export const ClientDetailPage: React.FunctionComponent = () => {
	const params = useParams();
	const id = Number(params.id);

	if (Number.isNaN(id)) {
		return <>V url chybí ID klienta</>;
	}

	return (
		<AuthenticatedLayout title="Detail klienta">
			<UserDetail id={id} />
		</AuthenticatedLayout>
	);
};
