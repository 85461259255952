import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import {
	type AuthorizationResponse,
	type PSD2Environment,
	getPsd2PispAuthorizePayment,
} from "Infrastructure/Api/Api";

interface AuthorizePaymentParams {
	signId: string;
	hash: string;
	refreshToken: string; // Added refreshToken parameter
}

const authorizePayment = async (
	environment: PSD2Environment,
	accessToken: string,
	params: AuthorizePaymentParams,
): Promise<AuthorizationResponse> => {
	const response = await getPsd2PispAuthorizePayment(
		params.signId,
		params.hash,
		accessToken,
		params.refreshToken, // Added refreshToken to the API call
		environment,
	);

	if (response.error) {
		throw new Error(response.error.message || "Failed to authorize payment");
	}

	if (response.status !== 200) {
		throw new Error(`Unexpected response status: ${response.status}`);
	}

	return response.data;
};

export const useAuthorizePaymentMutation = (
	environment: PSD2Environment,
	accessToken: string,
): UseMutationResult<AuthorizationResponse, Error, AuthorizePaymentParams> => {
	return useMutation({
		mutationFn: (params: AuthorizePaymentParams) =>
			authorizePayment(environment, accessToken, params),
	});
};
