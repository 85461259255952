import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import type { AccountDto } from "Infrastructure/Api/Api";
import type React from "react";

interface AispDataDisplayProps {
	accounts: AccountDto[];
}

export const AispDataDisplay: React.FC<AispDataDisplayProps> = ({
	accounts,
}) => {
	return (
		<TableContainer component={Paper}>
			<Typography variant="h6" sx={{ p: 2 }}>
				AISP Data
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Owners</TableCell>
						<TableCell>Currency</TableCell>
						<TableCell>Name</TableCell>
						<TableCell>Product</TableCell>
						<TableCell>IBAN</TableCell>
						<TableCell>ID</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{accounts.map((account) => (
						<TableRow key={account.id}>
							<TableCell>{account.ownersNames.join(", ")}</TableCell>
							<TableCell>{account.currency}</TableCell>
							<TableCell>{account.nameI18N}</TableCell>
							<TableCell>{account.productI18N}</TableCell>
							<TableCell>{account.identification.iban}</TableCell>
							<TableCell>{account.id}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
