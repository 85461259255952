import { useQuery } from "@tanstack/react-query";
import {
	type PSD2Environment,
	getPsd2PispPaymentStatus,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export function usePaymentStatusQuery(
	signId: string | null,
	environment: PSD2Environment,
) {
	return useQuery({
		queryKey: ["paymentStatus", signId, environment],
		queryFn:
			signId && environment
				? processResponse(() => getPsd2PispPaymentStatus(signId, environment))
				: undefined,
		refetchInterval: 3000,
	});
}
