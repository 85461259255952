import { useMutation } from "@tanstack/react-query";
import {
	type PSD2Environment,
	getPsd2AuthOneClickAndDone,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Args = {
	refreshToken: string;
	environment: PSD2Environment;
};

export function useOneClickAndDoneWithRefreshToken() {
	return useMutation({
		mutationFn: processResponse(({ refreshToken, environment }: Args) =>
			getPsd2AuthOneClickAndDone(refreshToken, null, environment),
		),
	});
}
