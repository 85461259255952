import { Box, Button, CircularProgress, Typography } from "@mui/material";
import type {
	PSD2Environment,
	PaymentInitiationRequest,
} from "Infrastructure/Api/Api";
import { useAuthorizePaymentMutation } from "Psd2/Api/Mutations/authorizePayment";
import { useInitiatePaymentMutation } from "Psd2/Api/Mutations/initiatePayment";
import { useAispAccountsQuery } from "Psd2/Api/Queries/useAispAccountsQuery";
import type React from "react";
import { useEffect, useState } from "react";

interface PispSectionProps {
	environment: PSD2Environment;
	accessToken: string;
	refreshToken: string;
}

export const PispSection: React.FC<PispSectionProps> = ({
	environment,
	accessToken,
	refreshToken,
}) => {
	const [paymentId, setPaymentId] = useState<string | null>(null);
	const [paymentData, setPaymentData] =
		useState<PaymentInitiationRequest | null>(null);

	const { data: accounts, isLoading: isLoadingAccounts } = useAispAccountsQuery(
		environment,
		accessToken,
	);

	const {
		mutate: initiatePayment,
		isPending: isPendingPayment,
		data: paymentResponse,
	} = useInitiatePaymentMutation(environment, accessToken);

	const {
		mutate: authorizePayment,
		isPending: isPendingAuthorization,
		data: authorizationResponse,
	} = useAuthorizePaymentMutation(environment, accessToken);

	useEffect(() => {
		if (accounts && accounts.accounts.length > 0) {
			const debtorAccount =
				accounts.accounts.find(
					(account) => account.productI18N === "Základní účet",
				) || accounts.accounts[0];

			const now = new Date();
			const paymentData: PaymentInitiationRequest = {
				instructionPriority: "INST",
				amount: 1,
				currency: debtorAccount.currency,
				requestedExecutionDate: now.toISOString().split("T")[0],
				chargeBearer: "SHA",
				debtorAccountIban:
					debtorAccount.identification.iban ?? debtorAccount.id,
				creditorName: "ECEGI GABRIEL",
				creditorCountry: "CZ",
				creditorAccountIban: "CZ9803000000000265972227",
				creditorStreetName: "",
				creditorBuildingNumber: "",
				creditorPostCode: "",
				creditorTownName: "",
				remittanceInformationUnstructured: "",
			};

			setPaymentData(paymentData);
		}
	}, [accounts]);

	const handleInitiatePayment = () => {
		if (paymentData) {
			initiatePayment(paymentData, {
				onSuccess: (response) => {
					setPaymentId(
						response.paymentIdentification.transactionIdentification,
					);
				},
			});
		}
	};

	const handleAuthorizePayment = () => {
		if (paymentResponse?.signInfo) {
			authorizePayment({
				signId: paymentResponse.signInfo.signId,
				hash: paymentResponse.signInfo.hash,
				refreshToken: refreshToken,
			});
		}
	};

	const handleRedirectToSigning = () => {
		if (authorizationResponse?.signingUrl) {
			window.open(authorizationResponse.signingUrl, "_blank");
		}
	};

	if (isLoadingAccounts) {
		return <CircularProgress />;
	}

	return (
		<Box>
			<Typography variant="h6">PISP</Typography>
			<Button
				variant="contained"
				color="secondary"
				onClick={handleInitiatePayment}
				disabled={isPendingPayment || !paymentData}
			>
				{isPendingPayment ? "Initiating..." : "Initiate Payment"}
			</Button>
			{paymentResponse && (
				<Box mt={2}>
					<Typography variant="h6">Payment Initiation Response:</Typography>
					<pre>{JSON.stringify(paymentResponse, null, 2)}</pre>
				</Box>
			)}
			{paymentId && (
				<Button
					variant="contained"
					color="primary"
					onClick={handleAuthorizePayment}
					disabled={isPendingAuthorization}
					sx={{ mt: 2 }}
				>
					{isPendingAuthorization ? "Authorizing..." : "Authorize Payment"}
				</Button>
			)}
			{authorizationResponse && (
				<Box mt={2}>
					<Typography variant="h6">Authorization Response:</Typography>
					<pre>{JSON.stringify(authorizationResponse, null, 2)}</pre>
					{authorizationResponse.signingUrl && (
						<Button
							variant="contained"
							color="primary"
							onClick={handleRedirectToSigning}
							sx={{ mt: 2 }}
						>
							Redirect to Signing Page
						</Button>
					)}
				</Box>
			)}
		</Box>
	);
};
