import { SignInPage } from "Auth/Pages/SignInPage";
import { DashboardPage } from "Dashboard/Pages/DashboardPage";
import { Error403Page } from "Infrastructure/Pages/Error403Page";
import { Error404Page } from "Infrastructure/Pages/Error404Page";
import { AuthenticatedRoute } from "Infrastructure/Routing/AuthenticatedRoute";
import { UnauthenticatedRoute } from "Infrastructure/Routing/UnauthenticatedRoute";
import { AppRouting, getRoute } from "Infrastructure/Utils/UrlUtils";
import { CallbackPage } from "Psd2/Pages/Callback";
import { PISPCallback } from "Psd2/Pages/PISPCallback";
import { ClientDetailPage } from "Users/Pages/UserDetailPage";
import { UsersPage } from "Users/Pages/UsersPage";
import { createBrowserRouter } from "react-router-dom";

const appRoutes = [
	{
		path: getRoute(AppRouting.SignIn),
		element: (
			<UnauthenticatedRoute>
				<SignInPage />
			</UnauthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Dashboard),
		element: (
			<AuthenticatedRoute>
				<DashboardPage />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Users),
		element: (
			<AuthenticatedRoute>
				<UsersPage />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.UserDetail),
		element: (
			<AuthenticatedRoute>
				<ClientDetailPage />
			</AuthenticatedRoute>
		),
	},
	{
		path: getRoute(AppRouting.Error403),
		element: <Error403Page />,
	},
	{
		path: getRoute(AppRouting.Psd2Callback),
		element: <CallbackPage />,
	},
	{
		path: getRoute(AppRouting.PispCallback),
		element: (
			<AuthenticatedRoute>
				<PISPCallback />
			</AuthenticatedRoute>
		),
	},
	{
		path: "*",
		element: <Error404Page />,
	},
];

export const appRouter = createBrowserRouter(appRoutes, {
	basename: import.meta.env.VITE_BASE_URL,
});
