import { useQuery } from "@tanstack/react-query";
import { getUsers } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const getUsersQueryKey = (offset: number, limit: number) => [
	"USERS_QUERY",
	offset,
	limit,
];

export function useUsersQuery() {
	return useQuery({
		queryKey: [getUsersQueryKey(0, 100)],
		queryFn: processResponse(() => getUsers(0, 100)),
	});
}
