import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import {
	type PSD2Environment,
	type PaymentInitiationRequest,
	type PaymentInitiationResponse,
	postPsd2PispInitiatePayment,
} from "Infrastructure/Api/Api";

const initiatePayment = async (
	environment: PSD2Environment,
	accessToken: string,
	paymentData: PaymentInitiationRequest,
): Promise<PaymentInitiationResponse> => {
	const response = await postPsd2PispInitiatePayment(
		paymentData,
		accessToken,
		environment,
	);

	if (response.error) {
		throw new Error(response.error.message || "Failed to initiate payment");
	}

	if (response.status !== 201) {
		throw new Error(`Unexpected response status: ${response.status}`);
	}

	return response.data;
};

export const useInitiatePaymentMutation = (
	environment: PSD2Environment,
	accessToken: string,
): UseMutationResult<
	PaymentInitiationResponse,
	Error,
	PaymentInitiationRequest
> => {
	return useMutation({
		mutationFn: (paymentData: PaymentInitiationRequest) =>
			initiatePayment(environment, accessToken, paymentData),
	});
};
