import { useQuery } from "@tanstack/react-query";
import { getUsersUserID } from "Infrastructure/Api/Api";

export const getUserDetailQueryKey = (id: number) => ["USER_DETAIL_QUERY", id];

export function useUserDetailQuery(id: number) {
	return useQuery({
		queryKey: getUserDetailQueryKey(id),
		queryFn: () => getUsersUserID(id),
	});
}
