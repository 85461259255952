import { useQuery } from "@tanstack/react-query";
import {
	type PSD2Environment,
	getPsd2AuthAuthorize,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export function useAuthorizeUrlQuery(environment: PSD2Environment) {
	return useQuery({
		queryKey: ["authorizeUrl", environment],
		queryFn: processResponse(() => getPsd2AuthAuthorize(environment)),
	});
}
