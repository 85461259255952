import styled from "@emotion/styled";
import { Box } from "@mui/material";
import type { UserListItemDto } from "Infrastructure/Api/Api";
import { AppRouting } from "Infrastructure/Utils/UrlUtils";
import { BlDefaultButton } from "Shared/Buttons/BlDefaultButton";
import { BlDataTable } from "Shared/DataTable/BlDataTable";
import { ExpandArrowIcon } from "Shared/Icons";
import { UnstyledLink } from "Shared/Routing/UnstyledLink";
import { useUsersQuery } from "Users/Api/Queries/useUsersQuery";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(BlDefaultButton)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;

  svg {
    color: ${(props) => props.theme.palette.text.secondary};
    width: 12px;
    height: auto;
    transform: rotate(-90deg);
  }
`;

const StyledCell = styled(Box)``;

type Props = {};

export const UsersTable: React.FunctionComponent<Props> = () => {
	const { data, isLoading } = useUsersQuery();

	const clients: UserListItemDto[] = data?.items ?? [];

	const navigate = useNavigate();

	return (
		<>
			<BlDataTable
				rows={clients.map((x) => ({ data: x }))}
				rowEntityID="userID"
				isLoading={isLoading}
				getHeaderCell={(column) => <div>{column.label ?? column.id}</div>}
				columns={[
					{ id: "userID", label: "ID" },
					{ id: "firstName", label: "Name" },
					{ id: "lastName", label: "Last name" },
					{ id: "login", label: "Login" },
					{ id: "action", label: "", maxWidth: 0, justifySelf: "flex-end" },
				]}
				rowsPerPage={5}
				onRowClick={(row) => {
					navigate(AppRouting.UserDetail.path(row.userID));
				}}
				getCell={(id, data) => {
					switch (id) {
						case "userID":
							return <StyledCell>{data.userID}</StyledCell>;
						case "firstName":
							return <StyledCell>{data.firstName}</StyledCell>;
						case "lastName":
							return <StyledCell>{data.lastName}</StyledCell>;
						case "login":
							return <StyledCell>{data.login}</StyledCell>;
						case "action":
							return (
								<StyledCell>
									<UnstyledLink to={AppRouting.UserDetail.path(data.userID)}>
										<StyledButton>
											<ExpandArrowIcon />
										</StyledButton>
									</UnstyledLink>
								</StyledCell>
							);
						default:
							return <StyledCell>{data[id]}</StyledCell>;
					}
				}}
			/>
		</>
	);
};
