import { Box, Button, Typography } from "@mui/material";
import type { PSD2Environment } from "Infrastructure/Api/Api";
import { useOneClickAndDoneWithRefreshToken } from "Psd2/Api/Mutations/useOneClickAndDoneWithRefreshToken";
import { useAuthorizeUrlQueryForOneClick } from "Psd2/Api/Queries/useAuthorizeUrlQueryForOneClick";
import type React from "react";

interface OneClickAndDoneSectionProps {
	environment: PSD2Environment;
	refreshToken: string | null;
}

export const OneClickAndDoneSection: React.FC<OneClickAndDoneSectionProps> = ({
	environment,
	refreshToken,
}) => {
	const { data: authorizeUrl } = useAuthorizeUrlQueryForOneClick(environment);
	const { mutate: oneClickAndDoneWithRefreshToken, isPending } =
		useOneClickAndDoneWithRefreshToken();

	const handleOneClickAndDone = () => {
		if (refreshToken) {
			oneClickAndDoneWithRefreshToken(
				{
					refreshToken,
					environment,
				},
				{
					onSuccess: (data) => {
						window.location.href = data.redirectUrl;
					},
				},
			);
			return;
		}
		window.location.href = authorizeUrl?.url ?? "";
	};

	return (
		<Box>
			<Typography variant="h6">One Click and Done</Typography>
			<Button
				variant="contained"
				color="primary"
				onClick={handleOneClickAndDone}
				disabled={isPending}
			>
				{isPending ? "Processing..." : "One Click and Done"}
			</Button>
		</Box>
	);
};
