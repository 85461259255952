import { ThemeProvider } from "@emotion/react";
import { ScopedCssBaseline } from "@mui/material";
import {
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { processSignOut } from "Auth/Hooks/useSignOut";
import { JWT_KEY } from "Auth/Models/AuthConstants";
import { type FetchResponse, setupClient } from "Infrastructure/Api/Api";
import { handleResponseError } from "Infrastructure/Api/ApiUtils";
import { getTheme } from "Infrastructure/Themes/Themes";
import { CzechResourcesDictionary } from "Infrastructure/Translations/CzechResourcesDictionary";
import { initSentry } from "Infrastructure/Utils/SentryUtils";
import { trackUserIdentification } from "Infrastructure/Utils/TrackingUtils";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "sonner";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import { appRouter } from "./Infrastructure/Routing/AppRouter";

function App() {
	initSentry();

	const theme = getTheme();
	i18n.use(initReactI18next).init({
		resources: {
			cs: CzechResourcesDictionary,
		},
		lng: "cs",
		fallbackLng: "cs",
		interpolation: {
			escapeValue: false,
		},
	});

	z.setErrorMap(zodI18nMap);

	const responseMiddleware = {
		name: "auth-middleware",
		fn: (response: FetchResponse<unknown, any>) => {
			if (response.status === 401) {
				processSignOut(queryClient);
			} else {
				trackUserIdentification();
			}
			return response;
		},
	};

	setupClient({
		apiUrl: import.meta.env.VITE_API_URL,
		jwtKey: JWT_KEY,
		responseMiddlewares: [responseMiddleware],
	});

	const queryClient = new QueryClient({
		defaultOptions: {
			mutations: {
				onError: handleResponseError,
			},
		},
		queryCache: new QueryCache({
			onError: handleResponseError,
		}),
	});

	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
			<ThemeProvider theme={theme}>
				<Toaster richColors position="top-left" />
				<ScopedCssBaseline>
					<RouterProvider router={appRouter} />
				</ScopedCssBaseline>
			</ThemeProvider>
		</QueryClientProvider>
	);
}

export default App;
