import { useQuery } from "@tanstack/react-query";
import { getAuthUserinfo } from "Infrastructure/Api/Api";

export const USER_INFO_QUERY_KEY = "USER_INFO";

export function useUserInfoQuery() {
	return useQuery({
		queryKey: [USER_INFO_QUERY_KEY],
		queryFn: () => getAuthUserinfo(),
	});
}
