import styled from "@emotion/styled";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ClientIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import {
	AppBar,
	Box,
	Button,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	type Theme,
	Toolbar,
	Typography,
} from "@mui/material";
import { useUserInfoQuery } from "Auth/Api/Queries/useUserInfoQuery";
import { useSignOut } from "Auth/Hooks/useSignOut";
import { Resources, useResource } from "Infrastructure/Translations/Resources";
import { AppRouting } from "Infrastructure/Utils/UrlUtils";
import { UnstyledLink } from "Shared/Routing/UnstyledLink";
import {
	type FC,
	type PropsWithChildren,
	createContext,
	useContext,
	useEffect,
} from "react";

type Props = {
	title: string;
};

const drawerWidth = 240;

const StyledBox = styled(Box)`
  display: flex;
`;

const StyledAppBar = styled(AppBar)(
	({ theme }: { theme: Theme }) => `
  z-index: ${theme.zIndex.drawer + 1};
`,
);

const StyledDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    box-sizing: border-box;
  }

  .MuiTypography-root {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledMainContent = styled(Box)`
  flex-grow: 1;
  padding: 16px;
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
`;

export const AuthenticatedLayout: FC<PropsWithChildren<Props>> = ({
	title,
	children,
}) => {
	const { t } = useResource();

	useEffect(() => {
		document.title = `${title} | ${t(Resources.Common.AppName)}`;
	}, [title, t]);

	const menuItems = [
		{
			text: "Dashboard",
			icon: <DashboardIcon />,
			to: AppRouting.Dashboard.path(),
		},
	];

	const { data } = useUserInfoQuery();

	const userLogin = data?.status === 200 ? data.data.login : "";
	const signOut = useSignOut();

	return (
		<StyledBox>
			{
				<>
					<StyledAppBar position="fixed">
						<Toolbar>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								width="100%"
							>
								<Typography variant="h6" noWrap component="div">
									{title}
								</Typography>
								<Stack
									direction={"row"}
									justifyContent="flex-end"
									alignItems="center"
									spacing={2}
								>
									<Typography>{userLogin}</Typography>
									<Button
										color="inherit"
										onClick={() => signOut()}
										startIcon={<LogoutIcon />}
									>
										{t(Resources.Common.SignOut)}
									</Button>
								</Stack>
							</Stack>
						</Toolbar>
					</StyledAppBar>
					<StyledDrawer variant="permanent">
						<Toolbar />
						<Box
							sx={{
								overflow: "auto",
							}}
						>
							<List>
								{menuItems.map((item) => (
									<UnstyledLink to={item.to} key={item.text}>
										<StyledListItem disablePadding>
											<ListItemButton>
												<ListItemIcon>{item.icon}</ListItemIcon>
												<ListItemText primary={item.text} />
											</ListItemButton>
										</StyledListItem>
									</UnstyledLink>
								))}
							</List>
						</Box>
					</StyledDrawer>
				</>
			}

			<StyledMainContent component="main">
				<Toolbar />
				{children}
			</StyledMainContent>
		</StyledBox>
	);
};
