import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
	type PSD2Environment,
	PaymentInstructionStatus,
} from "Infrastructure/Api/Api";
import { usePaymentStatusQuery } from "Psd2/Api/Queries/usePaymentStatusQuery";
import { tokenFamily } from "Psd2/Storage/TokenAtom";
import { AuthenticatedLayout } from "Shared/Layouts/AuthenticatedLayout";
import { useSetAtom } from "jotai";
import type React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const PISPCallback: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const signId = searchParams.get("signId");
	const accessToken = searchParams.get("accessToken");
	const refreshToken = searchParams.get("refreshToken");
	const environment = searchParams.get("environment") as PSD2Environment;
	const setTokens = useSetAtom(tokenFamily(environment));

	useEffect(() => {
		if (accessToken && refreshToken && environment) {
			setTokens({ accessToken, refreshToken });
		}
	}, [accessToken, refreshToken, environment, setTokens]);

	const {
		data: paymentStatus,
		isLoading,
		isError,
	} = usePaymentStatusQuery(signId, environment);

	const getStatusDisplay = (status: string): string => {
		switch (status) {
			case PaymentInstructionStatus.AcceptedTechnicalValidation:
				return "Přijato k technické validaci (ACTC)";
			case PaymentInstructionStatus.AcceptedCustomerProfile:
				return "Přijato pro zákaznický profil (ACSP)";
			case PaymentInstructionStatus.AcceptedSettlementCompleted:
				return "Přijato a dokončeno (ACSC)";
			case PaymentInstructionStatus.Rejected:
				return "Zamítnuto (RJCT)";
			default:
				return `Neznámý stav: ${status}`;
		}
	};

	const handleRedirect = () => {
		navigate("/");
	};

	return (
		<AuthenticatedLayout title="Platba dokončena">
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				minHeight="50vh"
			>
				{isLoading ? (
					<CircularProgress />
				) : isError ? (
					<>
						<Typography variant="h4" gutterBottom color="error">
							Chyba při zpracování platby
						</Typography>
						<Typography variant="body1" gutterBottom>
							Omlouváme se, ale při zpracování platby došlo k chybě. Zkuste to
							prosím znovu.
						</Typography>
					</>
				) : (
					<>
						{paymentStatus?.status && (
							<Typography variant="h4" gutterBottom>
								Stav platby: {getStatusDisplay(paymentStatus.status)}
							</Typography>
						)}
						<Typography variant="body1" gutterBottom>
							ID transakce: {paymentStatus?.signId}
						</Typography>
						{paymentStatus?.processedDateStarted && (
							<Typography variant="body2">
								Zahájeno:{" "}
								{new Date(paymentStatus.processedDateStarted).toLocaleString()}
							</Typography>
						)}
						{paymentStatus?.processedDateFinished && (
							<Typography variant="body2">
								Dokončeno:{" "}
								{new Date(paymentStatus.processedDateFinished).toLocaleString()}
							</Typography>
						)}
					</>
				)}
				<Button
					variant="contained"
					color="primary"
					onClick={handleRedirect}
					sx={{ mt: 3 }}
				>
					Přejít na domovskou stránku
				</Button>
			</Box>
		</AuthenticatedLayout>
	);
};
