import * as Sentry from "@sentry/react";
import {
	VITE_APPLICATION_VERSION,
	VITE_BUILD_NUMBER,
} from "Infrastructure/Utils/BuildUtils";
import { parseSentryUserFromJWT } from "Infrastructure/Utils/TrackingUtils";
import { useEffect } from "react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";

export const SENTRY_CONFIG = {
	IS_ENABLED: import.meta.env.VITE_SENTRY_IS_ENABLED === "true",
	IS_FEEDBACK_ENABLED:
		import.meta.env.VITE_SENTRY_IS_FEEDBACK_ENABLED === "true",
	DSN: import.meta.env.VITE_SENTRY_DSN as string,
	ORG: import.meta.env.VITE_SENTRY_ORG as string,
	PROJECT: import.meta.env.VITE_SENTRY_PROJECT as string,
};

export function initSentry() {
	//check if sentry was initialized

	if (SENTRY_CONFIG.IS_ENABLED && !Sentry.isInitialized()) {
		Sentry.init({
			dsn: SENTRY_CONFIG.DSN,
			integrations: [
				Sentry.reactRouterV6BrowserTracingIntegration({
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				}),
				Sentry.replayIntegration({
					maskAllText: false,
					maskAllInputs: false,
					networkDetailAllowUrls: [/Moone/, "localhost"],
					networkDetailDenyUrls: [/api\/health\/status/],
				}),
				Sentry.httpContextIntegration(),
				Sentry.dedupeIntegration(),
				Sentry.feedbackIntegration({
					buttonLabel: "Nahlásit chybu",
					nameLabel: "Jméno",
					emailLabel: "E-mail",
					messageLabel: "Popis chyby",
					cancelButtonLabel: "Zrušit",
					submitButtonLabel: "Odeslat",
					namePlaceholder: "Vaše jméno",
					emailPlaceholder: "Váš e-mail",
					messagePlaceholder: "Popište chybu",
					successMessageText: "Děkujeme za nahlášení chyby.",
				}),
			],
			environment: import.meta.env.VITE_VERSION as string,
			tracePropagationTargets: [/Moone/, "localhost"],
			replaysSessionSampleRate: 0,
			replaysOnErrorSampleRate: 1,
			tracesSampleRate: 1,
			normalizeDepth: 8,
			beforeSend(event) {
				// Check if it is an exception, and if so, show the report dialog
				if (event.exception && event.extra?.doNotShowDialog !== true) {
					Sentry.showReportDialog({ eventId: event.event_id, lang: "cs" });
				}
				return event;
			},
			initialScope: (scope) => {
				const user = parseSentryUserFromJWT();
				if (user) {
					scope.setUser(user);
					scope.setTag("user.login", user.username);
				}

				scope.setTag("app.version", VITE_APPLICATION_VERSION);
				scope.setTag("app.build", VITE_BUILD_NUMBER);

				return scope;
			},
			autoSessionTracking: false,
		});
	}
}
