import { Box, Paper, Typography } from "@mui/material";
import type { PSD2Environment } from "Infrastructure/Api/Api";
import { environmentConfigs } from "Psd2/Models/Models";
import { tokenFamily } from "Psd2/Storage/TokenAtom";
import { useAtom } from "jotai";
import type React from "react";
import { AispSection } from "./AispSection";
import { AuthSection } from "./AuthSection";
import { OneClickAndDoneSection } from "./OneClickAndDoneSection";
import { PispSection } from "./PispSection";
import { TokenDisplay } from "./TokenDisplay";

interface BankEnvironmentProps {
	environment: PSD2Environment;
}

export const BankEnvironment: React.FC<BankEnvironmentProps> = ({
	environment,
}) => {
	const [environmentTokens] = useAtom(tokenFamily(environment));
	const isAuthorized =
		!!environmentTokens.accessToken && !!environmentTokens.refreshToken;

	return (
		<Paper
			elevation={3}
			sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}
		>
			<Typography variant="h6">
				{environmentConfigs[environment].name}
			</Typography>

			<TokenDisplay
				accessToken={environmentTokens.accessToken}
				refreshToken={environmentTokens.refreshToken}
			/>

			<OneClickAndDoneSection
				environment={environment}
				refreshToken={environmentTokens.refreshToken}
			/>

			<AuthSection environment={environment} isAuthorized={isAuthorized} />

			{environmentTokens.accessToken && environmentTokens.refreshToken && (
				<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
					<AispSection
						environment={environment}
						accessToken={environmentTokens.accessToken}
					/>
					<PispSection
						environment={environment}
						accessToken={environmentTokens.accessToken}
						refreshToken={environmentTokens.refreshToken}
					/>
				</Box>
			)}
		</Paper>
	);
};
