import type { PSD2Environment } from "Infrastructure/Api/Api";
import { tokenFamily } from "Psd2/Storage/TokenAtom";
import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

export const CallbackPage: React.FunctionComponent = () => {
	const [searchParams] = useSearchParams();

	const accessToken = searchParams.get("accessToken");
	const refreshToken = searchParams.get("refreshToken");
	const environment = searchParams.get("environment") as PSD2Environment;

	const setTokens = useSetAtom(tokenFamily(environment));

	const updateTokens = useCallback(() => {
		if (accessToken && refreshToken) {
			setTimeout(() => {
				setTokens({ accessToken, refreshToken });
			}, 1000);
		}
	}, [accessToken, refreshToken, setTokens]);

	console.log("🚀 ~ accessToken:", accessToken);
	console.log("🚀 ~ refreshToken:", refreshToken);
	console.log("🚀 ~ environment:", environment);

	if (accessToken && refreshToken && environment) {
		updateTokens();
		return <Navigate to="/dashboard" replace />;
	}

	return (
		<div>
			<h1>Authentication Error</h1>
			<p>There was a problem during the authentication process:</p>
			<ul>
				{!accessToken && <li>Access token is missing</li>}
				{!refreshToken && <li>Refresh token is missing</li>}
				{!environment && <li>Environment is missing</li>}
			</ul>
			<p>Please try logging in again.</p>
		</div>
	);
};
