import { Box, Typography } from "@mui/material";
import type { PSD2Environment } from "Infrastructure/Api/Api";
import { useRefreshTokenMutation } from "Psd2/Api/Mutations/useRefreshTokenMutation";
import { useAuthorizeUrlQuery } from "Psd2/Api/Queries/useAuthorizeUrlQuery";
import { tokenFamily } from "Psd2/Storage/TokenAtom";
import { BlDefaultButton } from "Shared/Buttons/BlDefaultButton";
import { useAtom } from "jotai";
import type React from "react";

interface AuthSectionProps {
	environment: PSD2Environment;
	isAuthorized: boolean;
}

export const AuthSection: React.FC<AuthSectionProps> = ({
	environment,
	isAuthorized,
}) => {
	const { data: authorizeUrl } = useAuthorizeUrlQuery(environment);
	const [tokens, setTokens] = useAtom(tokenFamily(environment));
	const refreshTokenMutation = useRefreshTokenMutation(environment);

	const handleAuthorize = () => {
		if (authorizeUrl && !isAuthorized) {
			window.location.href = authorizeUrl.url;
		}
	};

	const handleDeleteTokens = () => {
		setTokens({ accessToken: null, refreshToken: null });
	};

	const handleRefreshToken = () => {
		if (tokens.refreshToken) {
			refreshTokenMutation.mutate({
				refreshToken: tokens.refreshToken,
				environment,
			});
		}
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
			<Typography variant="subtitle1">Authorization</Typography>
			<Box sx={{ display: "flex", gap: 2 }}>
				<BlDefaultButton
					variant="contained"
					onClick={handleAuthorize}
					disabled={isAuthorized}
				>
					{isAuthorized ? "Authorized" : "Authorize"}
				</BlDefaultButton>
				{isAuthorized && (
					<>
						<BlDefaultButton
							variant="outlined"
							onClick={handleRefreshToken}
							disabled={refreshTokenMutation.isPending}
						>
							Refresh Token
						</BlDefaultButton>
						<BlDefaultButton
							variant="outlined"
							color="error"
							onClick={handleDeleteTokens}
						>
							Delete Tokens
						</BlDefaultButton>
					</>
				)}
			</Box>
		</Box>
	);
};
