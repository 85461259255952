import { type QueryClient, useQueryClient } from "@tanstack/react-query";
import { USER_INFO_QUERY_KEY } from "Auth/Api/Queries/useUserInfoQuery";
import { JWT_KEY } from "Auth/Models/AuthConstants";
import { clearPsd2Tokens } from "Psd2/Storage/TokenAtom";

export function useSignOut() {
	const queryClient = useQueryClient();
	return () => {
		processSignOut(queryClient);
	};
}

export function processSignOut(queryClient: QueryClient) {
	if (localStorage.getItem(JWT_KEY)) {
		localStorage.removeItem(JWT_KEY);
		clearPsd2Tokens();
		queryClient.invalidateQueries({ queryKey: [USER_INFO_QUERY_KEY] });
	}
}
